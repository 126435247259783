.HelpByCategory {

  &__Category {
    max-width: 1140px;
    margin: 2rem auto;

    &_Backspace {
      display: flex;
      align-items: center;
      color: #696969;

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #696969;
      }

      svg {
        font-size: 25px;
        margin-right: 0.5rem;
      }
    }

    &_Name {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      svg {
        font-size: 60px;
        margin-right: 1rem;
        color: #696969;
      }
    }

    &_Frame {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &_Description {
        background-color: #fff;
        border: 1px solid #d7d7d7;
        box-shadow: 0 4px 50px rgb(0 0 0 / 5%);
        border-radius: 8px;
        margin: 0;
        width: 60%;
        padding: 1rem 2rem;

        h4 span {
          color: #313033;
        }
      }

      &_Icon {
        background-color: #fff;
        border: 1px solid #d7d7d7;
        box-shadow: 0 4px 50px rgb(0 0 0 / 5%);
        border-radius: 8px;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin: 0;
        width: 30%;
        padding: 1rem;

        svg {
          font-size: 60px;
          color: #696969;
        }

        h3 {
          margin: 8px 0;
        }

        p {
          font-size: 13px;
        }
      }
    }

    p {
      color: #939094;
    }
  }

  &__Highlight {
    max-width: 1140px;
    margin: 4rem auto;
  }

  &__Articles {
    max-width: 1140px;
    margin: 4rem auto;

    &_Frame {
      // display: flex;
      background-color: #fff;
      border: 1px solid #d7d7d7;
      border-bottom: none;
      box-shadow: 0 4px 50pxrgba(0,0,0,.05);
      padding: 1.5rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      a {
        text-decoration: none;
        color: #313033;
      }

      h4 {
        display: inline;
      }

      p {
        margin-bottom: 0;
        color: #313033;
        display: inline;
      }
    }

    #first {
      border-radius: 8px 8px 0 0;
    }

    #last {
      border-radius: 0 0 8px 8px;
      border-bottom: 1px solid #d7d7d7;;
    }
  }

  &__Contact {
    max-width: 1140px;
    padding: 0 2rem;
    margin: 5rem auto;
  }
}

@media only screen and (max-width: 1000px) {

  .HelpByCategory {
    margin: 0 1rem;
    padding: 0 1rem;

    &__Category {

      margin: 2rem auto;

      &_Name svg {
        font-size: 30px;
      }
      &_Frame {
        &_Description {
          width: 100%;
          margin-bottom: 1rem;
        }
        &_Icon {
          width: 100%;
        }
      }
    }

    &__Highlight {
      h5 {
        margin: 1rem;
      }
    }

    &__Articles {
      h5 {
        margin: 1rem;
      }
    }

    h1 {
      font-size: 30px;
    }

    h3 {
      font-size: 16px;
    }

    &__Contact {
      padding: 0;
    }

  }
}

@media only screen and (max-width: 767px) {

  .HelpByCategory {

    &__Articles {
      &_Frame p {
        display: none;
      }
    }
  }
}
