@charset "UTF-8";
@import url('./Assets/css/satoshi.css');
@import url('./Assets/css/sentient.css');
// @import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
	--pro-gray1: #f8f8f8;
	--pro-gray2: #bfbfbf;
	--pro-gray3: #696969;
	--pro-gray4: #565656;
	--pro-gray5: #444444;

	--pro-teal: #14a2b8;
	--pro-teal-light: #5abdcd;

	--pro-orange: #ff7043;
	--pro-orange-light: #fb926c;

	--pro-green: #8bc34a;
	--pro-green-light: #add580;
}

html {
	font-size: 100%;
	font-family: 'Satoshi-Regular';
} /*16px*/

body {
	background-color: #FFFBFE;
	padding: 0;
	margin: 0;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
	font-size: 16px;
	line-height: 24px;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: 400;
	line-height: 1.3;
}


h1 {
	font-family: 'Satoshi-Bold';
	font-size: 48px;
}

h2 {
	font-family: 'Satoshi-Bold';
	font-size: 32px;
	line-height: 44px;
}

h3 {
	margin: 16px 0;
	font-family: 'Satoshi-Bold';
	font-size: 20px;
	line-height: 32px;
}

h4 {
	font-family: 'Satoshi-Medium';
	font-size: 16px;
	line-height: 22px;
	margin-top: 16px;
}

h5 {
	font-family: 'Sentient-Regular';
	font-size: 20px;
	margin: 16px 0;
}

.SVGIcon {
  width: 1.5rem;
  height: 1.5rem;
}