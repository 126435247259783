div.Component__Hr{
    user-select: none;
    color: #BBB;
    width: 100%;
    text-align: center;
    line-height: 24px;
    margin: 16px 0px;
    margin-bottom: 24px;
    &::after {
        border-top: 1px solid #BBB;
        content: ' ';
        width: 100%;
        display: block;
        height: 1px;
        margin-top: -12px;
        z-index: 10;
    }
    span {
        background: #FFF;
        z-index: 20;
        padding: 0px 12px;
    }
}