.Contact {
  background-color: #ffffff;
  border-radius: 16px;
  border: 1px solid #d7d7d7;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  justify-content: center;

  &_Content {
    width: 35%;
    height: 100%;
    line-height: 1.5;

    &_Image {
      display: inline-block;
      position: relative;
      margin: 2rem;
      width: 18rem;
      height: 18rem;
      background: url('../../Assets/Contact.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    &_Attendance {
      flex-direction: column;
      justify-content: center;

      a {
        cursor: pointer;
        text-decoration: none;
        transition: 0.2s;
        color: #14a2b8;
      }

      .headline {
        position: relative;
        bottom: 1.5rem;
        height: 0;
      }
    }
  }
}

#contact_button {
  display: block;
  background: var(--pro-teal);
  color: white;
  padding: 14px 60px;
  margin: 1.5rem 0;
  border-radius: 100px;
  font-family: 'Satoshi-Black';
  font-size: 32px;
}

#report {
  text-decoration-line: underline;
}

@media only screen and (max-width: 1000px) {
  .Contact {
    align-items: center;
    margin: auto;
    height: auto;

    &_Content {
      width: 100%;

      &_Image {
        max-width: calc(100% - 4rem);
      }

      &_Attendance {
        width: min(90%, 450px);
        margin: auto;
        margin-top: 1rem;
        margin-bottom: 3rem;
      }
    }
  }

  #contact_button {
    margin: 1rem auto 1rem;
    padding: 14px 20px;
  }
}
