@charset "UTF-8";

.Search {
  background-color: #FFFBFE;
  margin-top: 3rem;

  &__Articles {
    max-width: 1140px;
    margin: auto auto 5rem;

    &_Wrapper {
      max-width: 1140px;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    &_Unit {
      background-color: #FFFFFF;
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: 0;
      width: calc(48%);
      max-height: 200px;
      text-align: left;
    }

    &_Category {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &_Content {

      &_In {
        display: flex;
      }
    }

    &_Image {
      display:inline-block;
      position:relative;
      width: auto;
      height: 200px;
      aspect-ratio: 1/1;
      background: url("../../Assets/placeholder.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 8px;
    }

    &_Text {
      padding: 1rem 1.5rem;
      width: 100%;

      h3 {
        font-size: 24px;
      }
    }
  }

  &__Suggestions {
    display: table;
    max-width: 1140px;
    margin: auto auto 5rem;
    height: 200px;
    text-align: center;
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    &_Table {
      display: table-cell;
      width: 33.3%;
      padding: 2rem;

      h3 {
        font-size: 24px;
      }

      svg {
        font-size: 48px;
      }
    }
  }
}

.text_small {
	font-size: 12px;
	padding: 1rem 0 0.5rem;
}

#link_search {
  color: #000000;
  font-size: 10px;
  padding: 0.01rem 0.5rem;
  margin: 0 12px 0 0;
  border: 1px solid #000000;
  border-radius: 100px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s;
}

#search {
  color: #FF7043;
}

#help {
  color: #14A2B8;
}

#contact {
  color: #6F33CF;
}

@media only screen and (max-width: 1000px) {

  .Search {
    margin-top: 1rem;

    &__Articles {
      padding: 0 2rem;
      flex-direction: column;
      margin-bottom: 3rem;

      &_Wrapper {
        flex-direction: column;
      }

      &_Unit {
        width: auto;
        max-height: 100%;
        margin-bottom: 1rem;
      }

      &_Content {
        &_In {
          flex-direction: column;
        }
      }

      &_Image {
        width: auto;
        border-radius: 8px 8px 0 0;
      }

      &_Text {
        width: auto;
        max-width: 100%;
        padding: 1rem;
      }
    }

    &__Suggestions {
      margin: 0 2rem 3rem;
      flex-direction: column;

      &_Table {
        display: block;
        width: auto;
        padding: 1rem;
      }
    }
  }
}
