.Help {
  text-align: center;
  margin: 6rem 0;

  &__Search {
    height: 270px;
    background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(255, 251, 254, 0) 55.73%,
        #fffbfe 100%
      ),
      url("../../Assets/email-pattern.webp");
    background-position: center;
    mix-blend-mode: multiply;
    padding: 0 2rem;

    h1 {
      margin-top: 0;
    }

    &_Bar {
      display: flex;
      align-items: center;
      margin: auto;
      width: 914px;
      height: 80px;
      background: #ffffff;
      box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.05);
      border-radius: 1000px;

      input {
        font-size: 20px;
        width: 90%;
        border: none;
        background-color: transparent;
        opacity: 0.8;
        padding: 20px 10px;
        margin-left: 16px;
      }

      input:focus-visible {
        outline: none;
      }

      button {
        background-color: transparent;
        border: none;
      }

      svg {
        font-size: 27px;
      }
    }
  }

  &__Articles {
    max-width: 1140px;
    margin: auto auto 2.5rem;
    padding: 0 17px;

    &_Wrapper {
      max-width: 1140px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }

    &_Unit {
      background-color: #FFFFFF;
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: 0 15px 30px;
      max-height: 200px;
      text-align: left;

      a {
        text-decoration: none;
        color: #000000;
      }
    }

    &_Category {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &_Content {

      &_In {
        display: flex;
      }
    }

    &_Image {
      display:inline-block;
      width: auto;
      height: 200px;
      aspect-ratio: 1/1;
      background: url("../../Assets/placeholder.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 8px;
    }

    &_Text {
      padding: 1rem 1.5rem;
      width: 292px;
      min-height: 168px;
      position: relative;

      h3 {
        display: -webkit-box;
        width: 100%;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      &_Title {
        text-overflow: ellipsis;
      }

      &_Small {
        font-size: 12px;
        padding: 1rem 0 0.5rem;
        position: absolute;
        bottom: 1rem;
        width: 88%;
        overflow: hidden;

        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          white-space: pre-wrap; 
        }
      }
    }
  }

  &__Sections {
    margin-top: 5rem;

    h3 {
      line-height: 32px;
      color: #313033;
      opacity: 0.35;
    }

    &_Frame {
      max-width: max(1200px, 85%);
      margin: auto;

      display: flex;
      justify-content: space-between;

      &_Unit {
        background-color: #ffffff;
        border: 1px solid #d7d7d7;
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        flex-direction: column;
        justify-content: center;
        width: calc(30%);
        padding: 1rem;

        svg {
          font-size: 50px;
          color: #b48ef1;
        }

        h3 {
          opacity: 100;
        }

        p {
          color: #939094;
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }

  &__Crecepro {
    margin-top: 5rem;
    padding: 0 2rem;

    h3 {
      opacity: 0.35;
    }

    &_Frame {
      max-width: 1400px;
      margin: auto;
      margin-top: 1.5rem;

      &_Unit {
        display: flex;
        align-items: center;
        background-color: #ffffff;
        border: 1px solid #d7d7d7;
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        margin-bottom: 1rem;
        padding: 1.5rem;

        h3 {
          opacity: 100;
          margin: 0;
        }

        svg {
          font-size: 30px;
          margin-right: 1rem;
        }

        select {
          font-size: 20px;
          margin: 0 1rem;
          width: 100%;
          color: #313033;
          background-color: transparent;
          opacity: 0.35;
          border: none;
        }
      }
    }

    .Expandable {
      background-color: #ffffff;
      border: 1px solid #d7d7d7;
      box-shadow: 0px 4px 50px rgb(0 0 0 / 5%);
      border-radius: 8px;
      margin-bottom: 1rem;
      font-family: "Satoshi-Bold";
      font-size: 1.25rem;
      color: black;
      position: relative;

      summary {
        padding: 1rem 1.25rem 1rem;
        transition: background-color 0.25s;
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }

      summary:hover {
        background-color: #00000012;
      }

      summary:not(:hover) {
        transition: none;
      }

      summary::-webkit-details-marker {
        display: none;
      }

      summary::after {
        content: " ►";
        opacity: 0.25;
        font-size: 1rem;
      }

      h4 {
        margin: 0;
        margin-left: 0.5rem;
        font-family: "Satoshi-Bold";
        font-size: 1.25rem;
        color: black;
        width: 11%;
      }

      svg {
        width: 2rem;
        height: 2rem;
      }

      .TitleFlavor {
        text-align: initial;
        // max-width: 1130px;
        width: 85%;
      }

      .TitleFlavor p {
        width: 100%;
        padding-left: 0.5rem;
        font-family: "Satoshi-Medium";
        font-size: 1.25rem;
        color: var(--pro-gray3);
        overflow: hidden;  
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
      }
    }

    .Expandable[open] summary:after {
      content: " ▼";
    }

    ul {
      list-style: none;
      padding-inline-start: 0;
      text-align: left;
      margin: 0;

      li a {
        font-family: "Satoshi-Medium";
        border-top: 1px solid #d7d7d7;
        padding: 0.5rem 1.5rem;
        font-size: 1rem;
        display: flex;
        align-items: center;
        transition: background-color 0.25s;
        cursor: pointer;
        text-decoration: none;
        color: #000000;

        .chipsGrid {
          display: flex;
          display: none;

          .articleChip:first-child{
            margin-left: .4rem;
          }

          .articleChip {
            font-size: 0.8rem;
            display: flex;
            border: 1px solid gray;
            border-radius: .4rem;
            padding: .2rem .4rem;
            margin: 0 .15rem;
          }

          .articleChip:last-child{
            margin-right: .4rem;
          }

        }

        h5 {
          font-family: "Satoshi-Bold";
          font-size: 1rem;
          border: none;
          padding: 0;
          margin: 0;
          width: 16%;
        }

        .ArticleFlavor {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: .75rem;
        }

        .ArticleFlavor p {
          overflow: hidden;  
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0;
        }
      }

      li:hover {
        background-color: #00000012;
      }

      li:not(:hover) {
        transition: none;
      }
    }
  }

  &__Contact {
    max-width: 1400px;
    padding: 0 2rem;
    margin: auto;
    margin-top: 5rem;
  }
}

#help_you {
  text-decoration: underline;
  text-decoration-color: #b48ef1;
}

#tour {
  color: #b48ef1;
}
#work {
  color: #696969;
}
#assignment {
  color: #14a2b8;
}
#client {
  color: #ff7043;
}
#sale {
  color: #8bc34a;
}

@media only screen and (max-width: 900px) {
  .Help {
    text-align: center;
    margin: 2rem 0;

    &__Search {

      h1 {
        font-size: 30px;
      }

      &_Bar {
        display: flex;
        align-items: center;
        margin: auto;
        width: auto;
        height: 80px;
        background: #ffffff;
        box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.05);
        border-radius: 1000px;
      }
    }

    &__Sections {
      &_Frame {
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        &_Unit {
          margin-top: 0.5rem;
          width: auto;
        }
      }
    }

    &__Crecepro {

      margin-top: 0;

      h2 {
        font-size: 25px;
      }

      &_Frame {
        margin: auto;
      }

      .Expandable h4 {
        width: 100%;
      }

      .TitleFlavor {
        display: none;
      }

      ul li a h5 {
        width: 100%;
      }

      .ArticleFlavor {
        display: none;
      }
    }
  }
}
