@charset "UTF-8";

a {
  h1 &,
  h2 &,
  h3 &,
  h4 &,
  h5 &,
  h6 & {
    color: #000;
    text-decoration: none;
  }
}

.News {
  background-color: #FFFBFE;
  margin-top: 3rem;

  &__Highlight {
    margin: 0 0 2.5rem;
    padding: 0 2rem;

    &_Wrapper {
      background-color: #FFFFFF;
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      max-width: 1110px;
      margin: auto;
      display: flex;
      justify-content: space-between;
    }

    &_Content {
      width: 50%;
      line-height: 1.5;

      &_In {
        display: flex;
        flex-direction: column;
      }
    }

    &_Image {
      display:inline-block;
      width: 100%;
      height: 100%;
      aspect-ratio: 1/1;
      background: url("../../Assets/placeholder.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 8px 0 0 8px;
      }
    &_Text {
      padding: 2rem 2.5rem;
      min-height: 491px;
      position: relative;

      &_Small {
        font-size: 12px;
        padding: 1rem 0 0.5rem;
        position: absolute;
        bottom: 1.5rem;
      }
    }
  }

  &__Articles {
    max-width: 1140px;
    margin: auto auto 2.5rem;
    padding: 0 17px;

    &_Wrapper {
      max-width: 1140px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }

    &_Unit {
      background-color: #FFFFFF;
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: 0 15px;
      max-height: 200px;
      text-align: left;
    }

    &_Category {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &_Content {

      &_In {
        display: flex;
      }
    }

    &_Image {
      display:inline-block;
      width: auto;
      height: 200px;
      aspect-ratio: 1/1;
      background: url("../../Assets/placeholder.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 8px;
    }

    &_Text {
      padding: 1rem 1.5rem;
      width: 100%;
      min-height: 168px;
      position: relative;

      h3 {
        display: -webkit-box;
        width: 100%;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      &_Title {
        text-overflow: ellipsis;
      }

      &_Small {
        font-size: 12px;
        padding: 1rem 0 0.5rem;
        position: absolute;
        bottom: 1rem;
      }
    }
  }

  &__MinorArticles {
    max-width: 1140px;
    margin: auto auto 2.5rem;
    padding: 0 17px;

    &_Wrapper {
      max-width: 1140px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
    }

    &_Unit {
      background-color: #FFFFFF;
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin: 0 15px;
      max-height: 150px;
      text-align: left;
    }

    &_Category {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &_Content {

      &_In {
        display: flex;
      }
    }

    &_Image {
      display:inline-block;
      width: auto;
      height: 150px;
      aspect-ratio: 1/1;
      background: url("../../Assets/placeholder.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 8px;
    }

    &_Text {
      padding: 1rem 1.5rem;
      width: 100%;
      min-height: 118px;
      position: relative;

      h3 {
        display: -webkit-box;
        width: 100%;
        font-size: 18px;
        margin: 0;
        line-height: 27px;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      &_Title {
        text-overflow: ellipsis;
      }

      &_Small {
        font-size: 12px;
        padding: 1rem 0 0.5rem;
        position: absolute;
        bottom: 0.5rem;
      }
    }
  }
}

@media only screen and (max-width: 1188px) {

  .News {
    margin-top: 1rem;

    &__Highlight {
      padding: 0 2rem;

      &_Wrapper {
        padding: 0;
        flex-direction: column;
      }

      &_Content {
        width: auto;
        margin: 0 0 1rem;
      }

      &_Text {
        width: auto;
        max-width: 100%;
        padding: 0 1rem;
        min-height: auto;

        &_Small {
          position: initial;
        }
      }

      &_Image {
        border-radius: 8px 8px 0 0;
        aspect-ratio: 3/1;
      }
    
    }

    &__Articles {
      flex-direction: column;
      margin-bottom: 3rem;

      &_Wrapper {
        flex-direction: column;
      }

      &_Unit {
        width: auto;
        max-height: 100%;
        margin-bottom: 1rem;
      }

      &_Content {
        &_In {
          flex-direction: column;
        }
      }

      &_Image {
        width: auto;
        border-radius: 8px 8px 0 0;
      }

      &_Text {
        width: auto;
        max-width: 100%;
        padding: 1rem;
      }
    }

    &__MinorArticles {
      &_Unit {
        max-height: fit-content;
      }

      &_Content {
        &_In {
          flex-direction: column;
        }
      }

      &_Image {
        border-radius: 8px 8px 0 0;
      }

      &_Text {
        width: auto;
      }
    }

  }
}

@media only screen and (max-width: 767px) {

  .News {
    &__Highlight {

      margin: 0 0 1rem;

      &_Image {
        aspect-ratio: 1/1;
        height: 290px;
      }

      &_Text {
        h2 {
          font-size: 20px;
          line-height: 32px;
        }
      }
    }

    &__Articles {

      margin-bottom: 1rem;

      &_Wrapper {
        display: block;
      }

      &_Content {
        width: 100%;
      }

      &_Image {
        height: 140px;
      }

      &_Text {
        min-height: 125px;

        h3 {
          -webkit-line-clamp: 2;
        }
      }
    }

    &__MinorArticles {

      margin: auto auto 1rem;

      &_Wrapper {
        display: block;
      }

      &_Unit {
        margin: 0 15px 1rem;
      }

      &_Content {
        width: 100%;
      }

      &_Image {
        height: 140px;
      }

      &_Text {
        min-height: 90px;

        h3 {
          -webkit-line-clamp: 2;
        }
      }
    }
  }
}