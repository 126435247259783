@charset "UTF-8";

.Homepage {
  background-color: #FFFBFE;
  margin-top: 3rem;

  &__Highlight {
    margin: 0 0 4rem;
    padding: 0 1.5rem;
    a {
      color: #000;
      text-decoration: none;
    }
  }

  &__Sections {
    max-width: 1140px;
    margin: auto auto 5rem;
    padding: 0 1.5rem;

    h5 {
      margin: 16px 10px;
    }

    &_Cells {
      display: grid;
      grid-template-columns: repeat(4, 1fr);;
      width: 100%;
    }

    &_Buttons {
      // display:inline-block;
      position:relative;
      width: auto;
      height: 100px;
      border: none;
      border-radius: 8px;
      margin: 0 0.75rem;

      svg {
        position: absolute;
        right: 0.6rem;
        font-size: 24px;
        margin: 0.5rem 0;
        color: #1C1B1F;
        }
      
      &_Each {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        background-color: transparent;
        border: none;
        font-family: 'Satoshi-Medium';
        font-size: 20px;
        cursor: pointer;
        text-transform: uppercase;
      }
    }
  }

  &__Articles {
    max-width: 1140px;
    margin: auto auto 5rem;
    padding: 0 1.5rem;

    h5 {
      margin: 16px 10px;
    }

    &_Wrapper {
      align-items: flex-start;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
      
      & > article {
        margin: 0 0.75rem;
      }
    }
  }
}

#highlight_wrapper_new {
  max-width: 1140px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

#highlight_text_new {
  width: 50%;
  line-height: 1.5;
}

#highlight_textContent_new {
  max-width: 552px;
  display: flex;
  flex-direction: column;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
#highlight_image {
    display:inline-block;
    position:relative;
    width: 100%;
    height: 290px;
    aspect-ratio: 1/1;
    background: url("../../Assets/placeholder.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;

    a {
      position: absolute;
      bottom: 0.6rem;
      font-size: 12px;
      padding: 0.1rem 1rem;
      margin: 0 0.6rem;
      }
}

#highlight_image_news {
  display:inline-block;
  position:relative;
  width: 264px;
  height: 150px;
  aspect-ratio: 1/1;
  background: url("../../Assets/placeholder.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;

  a {
    position: absolute;
    bottom: 0.6rem;
    font-size: 12px;
    padding: 0.1rem 1rem;
    margin: 0 0.6rem;
    }
}

.container {
  min-height: 100%;
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // gap: 0.5em;
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  & > article {
    flex: 1 1 calc(50% - 1em);
    display: flex; 
    flex-direction: column;
  }
}


.news_button {
  display: flex;
  justify-content: space-between;
}

#highlight_textSocial {
  margin-top: 1rem;
}

#highlight_textSocial svg {
  margin: 0 .2rem;
  opacity: .5;
  cursor: pointer;
}

#highlight_textMore {
  cursor: pointer;
}

#highlight_textSocial svg:hover {
  opacity: .25;
  transition: .2s;
}

#first_steps {
  background-color: #B48EF1;
}

#tasks {
  background-color: #9FF2FF;
}

#clients {
  background-color: #FFB8A1;
}

#sales {
  background-color: #BAE786;
}

// #article_unit {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: flex-start;
//   margin: 0;
//   width: calc(30%);
//   text-align: left;
// }

.article_category {
  margin-top: 1.5rem;
}

#article_thumbnail {
  display:inline-block;
  position:relative;
  width: 100%;
  height: 140px;
  border-radius: 8px;
  background: url("../../Assets/placeholder.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  a {
    position: absolute;
    bottom: 0.6rem;
    font-size: 12px;
    padding: 0.1rem 1rem;
    margin: 0 0.6rem;
    background-color: rgba(255, 251, 254, .5);
    }
}

.article_thumbnail:hover {
  cursor: pointer;
}

.article_title {
  margin: 1rem 0;

  a {
    font-size: 18px;
  }
}

.article_link {
  margin-top: 1rem;
}

.article_link:hover {
  cursor: pointer;
}

#article_loadMore {
  padding: .5rem;
  aspect-ratio: 1/1;
  width: 2rem;
  margin: auto;
  margin-top: 2rem;
  border-radius: 100px;
  background: var(--pro-teal);
  color: white;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

#article_loadMore:hover {
  opacity: .75;
}

#faq {
  background-color: #FFF1B5;
}

#feedback {
  background-color: #C4FDC7;
}

#bugs {
  background-color: #BAFBFF;
}

#attendance {
  background-color: #FFCFCF;
}

@media only screen and (max-width: 1023px) {

  .Homepage {
    margin-top: 1rem;

    &__Blog {
      margin: 2rem 0;

      &_Filters {
        flex-direction: column;
        align-items: center;
      }

      &_Category {
        display: none;
      }

    }

    &__Highlight {
      padding: 0 1rem;
      margin: 0 0 2rem;
    }

    &__Sections {
      margin: 0 0 4rem;
      padding: 0 1.3rem;
      flex-direction: column;
      width: auto;
      max-width: 100%;

      // &_Cells {
      //   flex-direction: column;
      // }

      &_Buttons {
        // width: 100%;
        margin-bottom: 1rem;
      }
    }

    &__Articles {
      padding: 0 1.3rem;
    }
  }
  
  #highlight_wrapper_new {
    padding: 0;
    flex-direction: column;
  }

  #highlight_text_new {
    width: auto;
    max-width: 100%;
    padding: 0 1rem;
  }

  #highlight_textContent_new {
    max-width: 100%;
    margin: 0 0 1rem;
  }

  .container {
    padding: 0;
    // flex-direction: column;
  }

  .odds {
    max-width: 100%;
    margin: 0;
  }

  #highlight_image_news {
    width: auto;
  }

  #articles_wrapper {
    padding: 0;
    flex-direction: column;
  }

  .ArticleUnit {
    width: auto;
    max-width: 100%;
  }

  #footer_columns {
    flex-direction: column;
  }

  .footer_unit {
    align-items: center;
    margin-bottom: 1rem;
  }

  #footer_legalSocial {
    flex-direction: column;
  }

  #footer_legalSocial>* {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 767px) {

  .Homepage {

    &__Sections {
      padding: 0 2rem;

      h5 {
        margin: 16px 0;
      }

      &_Cells {
        display: flex;
        flex-direction: column;
      }

      &_Buttons {
        margin: 10px 0;
      }
    }

    &__Articles {
      padding: 0 2rem;

      h5 {
        margin: 16px 0;
      }

  
      &_Wrapper {
        display: flex;
        flex-direction: column;

        > article {
          margin: 0;
        }
      }
    }
  }
  #articles_wrapper_new {
    justify-content: center;
  }

  .ArticleUnit {
    width: 100%;
  }

  .article_unit {
    width: 85% !important;
  }

  .container {
    flex-direction: column;
  }
}