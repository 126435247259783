.Search{

  &__Blog {
    margin: 1rem 0 3rem;
    padding: 0 1.5rem;

    &_Filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--pro-gray3);
      font-weight: 500;
    }

    &_Wrapper {
      max-width: 1140px;
      margin: auto;
    }

    &_Category {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}

#blog_articles {
  color: #000000;
  font-size: 14px;
  padding: 0.3rem 1.25rem;
  margin: 0 12px;
  border: 1px solid #000000;
  border-radius: 100px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s;
  background-color: rgba(255, 251, 254, .5);
}

/* SEARCH FILTER*/
#blog_search_ {
  display: flex;
  align-items: baseline;
  cursor: pointer;
  input {
    width: 250px;
    border: none;
    border-bottom: 1.5px solid var(--pro-gray3);
    opacity: 0.3;
    background-color: transparent;
  }
}

#blog_search_button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

#blog_search_button svg {
  width: 1rem;
  height: 1rem;
}

@media only screen and (max-width: 1000px) {

  .Search {
    margin-top: 1rem;

    &__Blog {
      margin: 2rem 0;

      &_Filters {
        flex-direction: column;
        align-items: center;
      }

      &_Category {
        display: none;
      }
    }
  }

  #blog_search_ {
    width: auto;
    max-width: 100%;
    padding: 0;
  }
}