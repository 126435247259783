.Article {
  background-color: #fffbfe;
  margin-top: 1rem;
  padding: 0 2rem;

  &__All {
    max-width: 1128px;
    margin: auto auto 5rem;
    padding-bottom: 5rem;
    text-align: left;
    background: #ffffff;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    position: relative;
  }

  &__Category {
    display: flex;
    align-items: center;
    color: #696969;
    max-width: 1128px;
    margin: 2rem auto;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #696969;
    }

    svg {
      font-size: 25px;
      margin-right: 0.5rem;
    }
  }

  &__Highlight {
    margin: 0 0 4rem;
    padding: 4rem 2rem;

    &_Wrapper {
      display: flex;
      justify-content: space-between;
    }

    &_Content {
      width: 50%;
      line-height: 1.5;

      &_In {
        display: flex;
        flex-direction: column;
      }
    }

    &_Image {
      display: inline-block;
      position: relative;
      width: 100%;
      height: 400px;
      background: url("../../Assets/placeholder.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 8px;
    }
    &_Text {
      padding: 0 0 0 2.5rem;

      &_SocialIcons {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        width: 60%;

        &_UnitLink {
          cursor: pointer;
          display: inline-block;
          position: relative;
          width: auto;
        }
      }
    }
  }

  &__Body {
    width: 704px;
    margin: auto auto 4rem;

    p {
      line-height: 30px;
      letter-spacing: 0.25px;
    }
  }

  &__Buttons {
    display: flex;
    font-size: 18px;
    font-weight: 600;
    margin: 6rem 0 0;

    &_Previous {
      width: 260px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: var(--pro-teal);
      color: white;
      padding: 0.5rem 1.25rem;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: 0.2s;
      position: absolute;
      left: 3%;
    }

    &_After {
      width: 260px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: var(--pro-teal);
      color: white;
      padding: 0.5rem 1.25rem;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: 0.2s;
      position: absolute;
      right: 3%;
    }

    &_Link {
      text-decoration: none;
      color: white;
    }
  }

  &__LowerArticles {
    max-width: 1140px;
    margin: auto auto 5rem;

    h5 {
      margin: 1rem 0.5rem;
    }
  }
}

.text_small {
  font-size: 16px;
  padding: 1rem 0 0.5rem;
}

#link_category {
  color: #000000;
  font-size: 14px;
  padding: 0.3rem 1.25rem;
  margin: 0 12px 0 0;
  border: 1px solid #000000;
  border-radius: 100px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s;
}

@media only screen and (max-width: 1000px) {
  .Article {
    &__All {
      width: auto;
      margin: 0 0 2rem;
      padding-bottom: 4rem;
    }

    &__Category {
      width: auto;
      flex-flow: wrap;

      h3 {
        margin: 0;
        font-size: 16px;
      }
    }

    &__Highlight {
      padding: 2rem;
      margin: 0;

      &_Wrapper {
        padding: 0;
        flex-direction: column;
      }

      &_Content {
        width: auto;
        margin: 0 0 1rem;
      }

      &_Text {
        width: auto;
        max-width: 100%;
        padding: 0;
      }

      &_Image {
        width: auto;
        height: auto;
        aspect-ratio: 3/2;
      }

      h1 {
        font-size: 32px;
      }
    }

    &__Body {
      width: auto;
      padding: 0 2rem;
    }

    &__Buttons {
      &_Previous {
        left: 5%;
        width: 30%;
      }

      &_After {
        width: 30%;
        right: 5%;
      }
    }

    &__LowerArticles {
      flex-direction: column;
    }
  }
}
