@charset "UTF-8";

.AppFrame {
  &__Menu {
    background-color: #FFFFFF;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
    font-family: 'Satoshi-Medium';

    &_Wrapper {
      padding: 0 20px;
      min-height: 85px;
      max-width: 1108px;
      margin: auto;

      display: flex;
      justify-content: space-between;
      align-items: center;

      &_Logo {
        font-family: 'Sentient-Medium';
        font-size: 22px;
        text-decoration: none;
        color: #000000;
      }

      button {
        display: none;
      }
    }
    &_Nav {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;

      #home {
        display: none;
      }

      &_Links {
        &_Unit {
          color: #000000;
          cursor: pointer;
          border-bottom: 2px solid white;
          text-decoration: none;
          transition: 0.2s;
          padding: 0 8px;
          margin: 0 12px;

          svg {
            display: none;
          }
        }
    
        &_Unit:hover {
          border-bottom-color: #14A2B8;
          transition: 0.2s;
        }

        &_Unit:focus {
          border-bottom-color: #14A2B8;
        }

        &_GoToApp {
          background: var(--pro-teal);
          color: white;
          padding: 0.5rem 1.25rem;
          margin: 0 12px;
          border-radius: 100px;
          cursor: pointer;
          text-decoration: none;
          transition: 0.2s;
    
          svg {
            display: none;
          }
        }

        // &_DarkMode {
        //   background: #FFF1B5;
        //   color: black;
        //   padding: 8px 12px;
        //   margin: 0 12px;
        //   border-radius: 100px;
        //   cursor: pointer;
        //   transition: 0.2s;
    
        //   #dark {
        //     display: none;
        //   }
        //}
      }

      &_SocialIcons {
        display: none;
      }
    }

  }


  &__Footer {
    background-color: #F4EFF4;
    padding: 80px 0 40px;
    color: #000000;

    #footer_wrapper {
      max-width: 1140px;
      margin: auto;

      display: flex;
      flex-direction: column;
    }

    #footer_logo img {
      height: 3rem;
    }

    .footer_columns {
      display: flex;
      justify-content: space-between;
      margin: 0 2rem;
    }

    .footer_unit {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      width: 100%;
    }

    .footer_unit > * {
      margin-bottom: 1.25rem;
    }

    .footer_unitHeader {
      font-weight: 900;
      font-size: 15px;
    }

    .footer_unitLink {
      display: flex;
      font-size: 15px;
      margin-bottom: 1.25rem;

      a {
        text-decoration: none;
        color: #000;
        cursor: pointer;
        display: flex;
      }
    }

    .footer_unitLink svg {
      margin-right: 16px;
      height: 24px;
    }

    .social_icons {
      .footer_unitLink {
        display: flex;
        justify-content: flex-start;
      }
      p {
        margin: 0;
      }
    }

    #footer_lowerDivider {
      border-bottom: 1px solid var(--pro-gray1);
      margin: 5rem 0 1.5rem;
    }

    #footer_legalSocial {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #footer_legalGrid {
      display: flex;
      align-items: center;
    }

    #footer_legalGrid > small {
      margin-left: 0.75rem;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .AppFrame {
    &__Menu {

      &_Wrapper {
        display: flex;
        flex-flow: row wrap;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.2s;
        color: #000000;
        background-color: transparent;
        border: none;

        svg {
          font-size: 28px;
        }
      }
      &_Nav {
        width: 100%;
        display: none;
        position: absolute;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        #home {
          display: flex;
        }
          
        &_Links {
          display: flex;
          flex-direction: column;
          width: 100%;

          &_Unit {
            padding: 1rem;
            margin: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            align-items: center;
          }

          &_Unit:hover {
            color: #FFFFFF;
            transition: 0.2s;
          }

          &_GoToApp {
            width: 130px;
            align-items: center;
            justify-content: center;
            margin: 1rem 0.5rem;
            padding: 0.4rem 0.7rem;
          }

          // &_DarkMode {
          //   width: 130px;
          //   align-items: center;
          //   justify-content: center;
          //   padding: 0.4rem 0.7rem;
          //   margin-left: 0.5rem;
          //   text-decoration: none;

          //   #dark {
          //     display: flex;
          //   }
          // }

          a {
            display: flex;
          }

          svg {
            display: flex;
            font-size: 20px;
            margin-right: 0.5rem;
          }
        }

        &_SocialIcons {
          margin-top: auto;
          margin-bottom: 1rem;
          display: flex;
          justify-content: space-between;
          width: 80%;
          align-items: center;

          &_Unit {
            cursor: pointer;
            display: inline-block;
            position: relative;
            width: auto;

            a {
              color: #000000;
            }
          }
        }          
      }

      &_Nav.isActive {
        display: flex;
        justify-content: flex-start;
        height: calc(100vh - 85px);
        opacity: 1;
        background-color: #FFFBFE;
        top: 85px;
        left: 45%;
        width: 55%;
        z-index: 1;
      }

    }
    &__Footer {

      .footer_unit {
        margin-bottom: 1rem;
        padding: 0 2rem;
        display: block;
        text-align: center;
      }

      .footer_unitLink {
        display: inline-flex;
        width: auto;
        vertical-align: middle;

        p {
          display: none;
        }
      }

      #footer_legalSocial {
        flex-direction: column;
      }

      #footer_legalSocial > * {
        margin-bottom: 1rem;
      }
    }
  }
}
