.LowerArticles {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;

  &_Unit {
    background-color: #ffffff;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0 0.5rem 1rem;
    max-height: 200px;
    text-align: left;
  }

  &_Category {
    display: flex;
    align-items: center;
    cursor: pointer;

    &_Small {
      font-size: 12px;
      padding: 1rem 0 0.8rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: absolute;
      bottom: 0;
    }
  }

  &_Content {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;

    &_In {
      display: flex;
    }
  }

  &_Image {
    display: inline-block;
    width: auto;
    height: 154px;
    aspect-ratio: 1/1;
    background: url("../../Assets/placeholder.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
  }

  &_Text {
    padding: 0.8rem 1rem;
    width: 100%;
    position: relative;

    h4 {
      margin: 0.5rem 0 0;
      display: -webkit-box;
      width: 100%;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}

#link_news {
  color: #000000;
  font-size: 10px;
  padding: 0.01rem 0.5rem;
  margin: 0 12px 0 0;
  border: 1px solid #000000;
  border-radius: 100px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s;
}

@media only screen and (max-width: 1000px) {
  .LowerArticles {
    display: flex;
    flex-direction: column;

    &_Unit {
      width: auto;
      max-height: 100%;
      margin-bottom: 1rem;
    }

    &_Content {
      &_In {
        flex-direction: column;
        width: 100%;
      }
    }

    &_Category {
  
      &_Small {
        position: inherit;
        padding: 1rem 0 0;
      }
    }

    &_Image {
      width: auto;
      border-radius: 8px 8px 0 0;
    }

    &_Text {
      width: auto;
      max-width: 100%;
      padding: 1rem;
    }
  }
}
